.Component-Input {
  &-Element {
    flex: 1;
  }
  &-Position,
  &-Scaling {
    display: flex;
    justify-content: space-between;
    &-Textfield {
      color: #fff;
      background: transparent;
      border: none;
      border-bottom: 1px solid black;
      margin-left: 0.5rem;
      &:focus {
        outline: none;
      }
    }
  }
}
