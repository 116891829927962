html {
  box-sizing: border-box;
}

body {
  background: linear-gradient(210deg, #1e87d4 0%, #2e559b 100%);
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 40px;
  font-weight: 100;
  letter-spacing: 1pt;
}

button {
  cursor: pointer;
}

.ReactTable {
  border: none !important;
  .rt-th {
    border: none !important;
  }
  .rt-thead {
    box-shadow: none !important;
    border-bottom: 1px solid white !important;
    text-transform: uppercase;
  }

  .rt-tr-group {
    border: none !important;
    border-bottom: 0.5px solid #ccc !important;
    padding: 0.25rem 0;
  }

  .rt-td {
    border: none !important;
  }
}
