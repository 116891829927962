//@import '../../styles/variables';

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input[type='radio'] {
  display: none;
}

.Radio-Label {
  display: block;
  position: relative;
  padding-left: 30px;
  font-weight: bold;
  font-size: 0.75rem;
  padding-top: 5px;
}

.Radio-Label span {
  background: white;
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #eaeaea;
  box-shadow: 0px 2px 4px grey;
  position: absolute;
  top: 3px;
  left: 0;

  transition: all 0.1s linear;
}

input[type='radio'] + span {
  border-radius: 50%;
}

.Radio-Label:hover span {
  border: 1px solid #bbb;
}

input[type='radio']:checked + span {
  border: 4px solid white;
  background: #2eb4ff;
}
