.Header {
  background-color: white;
  color: black;
  display: flex;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  height: 3.5rem;
  padding: 0 1rem;
  justify-content: space-between;
  width: 100%;
  z-index: 1;

  @media (min-width: 912px) {
    padding: 0 2rem;
  }
}

.Header-Item {
  display: flex;
  align-items: center;
}

.Header-Logo {
  height: 35px;
  width: 35px;
}

.Header-Navlink {
  color: black;
  margin: 0 1rem;
  text-transform: uppercase;
  text-decoration: none;
}

.Header-Navlink-Active {
  color: #2eb4ff;
  margin: 0 1rem;
  text-transform: uppercase;
  text-decoration: none;
}

.Header-Logout {
  border: none;
  font-size: 1rem;
  text-transform: uppercase;
}

.Header-Logout:hover {
  cursor: pointer;
}
