.Component-Wrapper {
  padding: 1rem 3rem;
  max-width: 1260px;
  margin: auto;

  h1 {
    text-align: center;
  }

  &-ButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}
