// Variables:

.DeleteConfirm-Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  z-index: 899;

  background: rgba(52, 59, 69, 0.9);
}

.DeleteConfirm-Content {
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  width: 455px;
  height: 380px;

  z-index: 900;

  background: white;

  text-align: center;
  padding: 0 1rem;
  &-Icon {
    position: absolute;

    top: 1rem;
    right: 1rem;
  }

  &-Main {
    margin: 0 auto;
    padding: 1rem 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    &-Headline {
      font-size: 40px;
      font-weight: 200;
    }

    &-Question {
      flex: 1;
      font-size: '.9rem';
      letter-spacing: '0.3px';
      line-height: 1.5;
    }
  }
}
