.EditProperty {
  display: flex;
  margin: 0.5rem 0;
  width: 100%;

  /* @media (min-width: 768px) {
    width: 50%;
  } */

  &-Description {
    width: 33%;
    margin-top: 3px;
  }

  &-Input {
    width: 67%;

    &-Textfield {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000;
      color: #fff;
      font-size: 1.125rem;
      padding-bottom: 3px;
      width: 100%;

      &::placeholder {
        color: #aaa;
      }

      &:focus {
        border-bottom: 1px solid #fff;
        outline: none;
      }
    }

    &-Textarea {
      background: transparent;
      border: 0.5px solid #000;
      color: #fff;
      font-size: 1.125rem;
      padding: 0.5rem;
      width: 100%;
    }
  }
}
