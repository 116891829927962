.Button {
  &-First {
    background: #fff;
    border: 1px solid #fff;
    cursor: pointer;
    letter-spacing: 0.5pt;
    padding: 0.65rem 2rem;
    text-transform: uppercase;
    width: 100%;
    max-width: 250px;
    font-weight: bold;
  }

  &-Second {
    background: transparent;
    color: #fff;
    cursor: pointer;
    border: 1px solid #fff;
    letter-spacing: 0.5pt;
    padding: 0.65rem 2rem;
    text-transform: uppercase;
    width: 100%;
    max-width: 250px;
    font-weight: bold;
  }

  &-Third {
    background: rgba(0, 140, 215, 1);
    color: #fff;
    cursor: pointer;
    border: 1px solid rgba(0, 140, 215, 1);
    letter-spacing: 0.5pt;
    padding: 0.65rem 2rem;
    text-transform: uppercase;
    width: 100%;
    max-width: 250px;
    font-weight: bold;

    &:hover {
      color: rgba(0, 140, 215, 1);
      background: white;
    }
  }
  &-Fourth {
    background: transparent;
    color: #000;
    cursor: pointer;
    border: 1px solid #000;
    letter-spacing: 0.5pt;
    padding: 0.65rem 2rem;
    text-transform: uppercase;
    width: 100%;
    max-width: 250px;
    font-weight: bold;

    &:hover {
      color: rgba(0, 140, 215, 1);
      border: none;
    }
  }

  &-Delete {
    background: #883e4f;
    color: white;
    cursor: pointer;
    border: 1px solid #883e4f;
    letter-spacing: 0.5pt;
    padding: 0.65rem 2rem;
    text-transform: uppercase;
    width: 100%;
    max-width: 250px;
    font-weight: bold;

    &:hover {
      background: white;
      color: #883e4f;
    }
  }
}
