.Organizations {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;

  justify-content: center;
  width: 100%;

  &-Options {
    display: flex;
    flex-direction: column;
    min-width: 240px;
    &-Searchform {
      width: 100%;
      margin-bottom: 0.5rem;

      &-Textfield {
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 1px solid #000;
        color: #fff;
        font-size: 0.85rem;
        padding: 0.25rem;
        max-width: 250px;
        margin-bottom: 0.5rem;

        &::placeholder {
          color: #aaa;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }

  &-Content {
    margin-top: 1.5rem;
    height: 100%;
    flex: 1;
    width: 100%;
    max-width: 1260px;
  }
}
