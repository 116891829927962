.DefaultLayout {
  color: #fff;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  margin: 0;

  &-Content {
    padding: 1rem 1rem;
    margin-top: 3.5rem;

    @media (min-width: 768px) {
      padding: 1rem 1.5rem;
    }

    @media (min-width: 912px) {
      padding: 1rem 2rem;
    }
  }
}
